import React, { useRef, useState, useCallback, PropsWithChildren, ReactElement } from 'react';

import { useContext } from 'react';
import { View } from 'react-native';
import Animated, { Easing, useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated';
import useDeviceSize, { DEVICE_SIZES } from '../../hooks/useDeviceSize';
import useTheme from '../../hooks/useTheme';

export const POPUP_ANIMATION_ENTERING_DURATION = 400;
export const POPUP_ANIMATION_EXITING_DURATION = 350;

export interface PopupContextInterface {
  openPopup: (config: PopupConfigType) => void;
  closePopup: () => void;
  popupOpened: boolean;
}

export type PopupConfigType = {
  key?: string;
  component: ReactElement | null;
  onClose?: () => void;
  fullscreen?: boolean;
};

export const PopupContext = React.createContext<PopupContextInterface>({
  openPopup: () => {},
  closePopup: () => {},
  popupOpened: false,
});

export const usePopup = () => useContext(PopupContext);
export const DefaultContent = <View />;

const defaultPopupConfig: PopupConfigType = {
  component: DefaultContent,
  onClose: undefined,
};

const FADE_DURATION = 700;
const MAX_WIDTH = 1000;

interface Props {}
const PopupProvider = ({ children }: PropsWithChildren<Props>) => {
  const theme = useTheme();
  const { width: screenWidth, height: screenHeight, size: deviceSize } = useDeviceSize();

  const [isOpen, setIsOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const requestedHeight = useRef(0);
  const layoutIterator = useRef(0);
  const previousIterator = useRef(0);
  const isOpenRef = useRef(false);

  const popupConfig = useRef<PopupConfigType>(defaultPopupConfig);

  const onKeydown = (e: KeyboardEvent) => {
    if (!!isOpenRef.current && e.key === 'Escape') {
      closePopup();
    }
    //alert(e.nativeEvent.key)
  };

  React.useEffect(() => {
    window.addEventListener('keydown', onKeydown);
    return () => window.removeEventListener('keydown', onKeydown);
  }, []);

  const openPopup = (config: PopupConfigType) => {
    /* if (!!isOpenRef.current) {
      alert('woops');
      reset(); // reset, just to be sure the next attempt will succeed
      return;
    } */
    isOpenRef.current = true;
    setIsClosing(false);

    popupConfig.current = config;

    setIsOpen(true);
    opacity.value = withTiming(1, { duration: FADE_DURATION, easing: Easing.bezier(0.25, 0.1, 0.25, 1) });
  };

  const reset = useCallback(() => {
    requestedHeight.current = 0;
    layoutIterator.current = 0;
    previousIterator.current = 0;
  }, []);

  const closePopup = () => {
    isOpenRef.current = false;
    reset();
    setIsClosing(true);
    popupConfig.current.onClose?.();

    opacity.value = withTiming(
      0,
      {
        duration: FADE_DURATION * 0.6,
        easing: Easing.bezier(0.25, 0.1, 0.25, 1),
      },
      () => {
        setIsOpen(false);
        setIsClosing(false);
      }
    );
  };

  const opacity = useSharedValue(0);
  const animatedOpacity = useAnimatedStyle(() => {
    return {
      opacity: opacity.value,
    };
  });

  const mobileView = deviceSize < DEVICE_SIZES.m;
  const fullscreen = mobileView && popupConfig.current.fullscreen;
  const maxWidth = fullscreen ? undefined : Math.min(MAX_WIDTH, screenWidth - theme.space.l * 2);
  const minWidth = fullscreen ? undefined : Math.min(MAX_WIDTH, maxWidth || MAX_WIDTH);
  const maxHeight = fullscreen ? screenHeight : screenHeight - theme.space.m * 2;
  //const minHeight = mobileView ? maxHeight : undefined;

  return (
    <PopupContext.Provider
      value={{
        openPopup,
        closePopup,
        popupOpened: isOpen && !isClosing,
      }}
    >
      {children}
      <>
        {isOpen && !!popupConfig.current.component && (
          <Animated.View
            style={[
              {
                position: 'absolute',
                width: screenWidth,
                height: screenHeight,
                zIndex: 20,
                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                alignItems: 'center',
                justifyContent: 'center',
                top: 0,
                left: 0,
              },
              animatedOpacity,
            ]}
            pointerEvents={isClosing ? 'none' : 'auto'}
          >
            {/* <TouchableOpacity
              style={{ position: 'fixed', width: '100%', height: '100%' }}
              onPress={() => {
                closePopup();
              }}
            /> */}
            <Animated.View
              style={[
                {
                  backgroundColor: theme.colors.popupBackground,
                  marginTop: fullscreen ? undefined : theme.space.m,
                  borderRadius: 4,
                  minWidth,
                  maxWidth,
                  maxHeight,
                  cursor: 'default',
                },
              ]}
            >
              <View style={{ flex: 1 }}>{popupConfig.current.component}</View>
            </Animated.View>
          </Animated.View>
        )}
      </>
    </PopupContext.Provider>
  );
};

export default PopupProvider;
