import { useEffect, useState } from 'react';
import { Dimensions } from 'react-native';

const windowDimensions = Dimensions.get('window');
const screenDimensions = Dimensions.get('screen');

export enum DEVICE_SIZES {
  xs = 400, // iPhone SE = 375
  s = 450, // phone XR = 414
  m = 820, // tablet = 768
  l = 1000, // desktop = 1024
  xl = 1400, // large desktop = 1920
}

export default function useDeviceSize() {
  const [dimensions, setDimensions] = useState({
    window: windowDimensions,
    screen: screenDimensions,
  });

  useEffect(() => {
    const subscription = Dimensions.addEventListener('change', ({ window, screen }) => {
      setDimensions({ window, screen });
    });
    return () => subscription?.remove();
  }, []);

  const width = dimensions.screen.width;
  const height = dimensions.screen.height;

  let size: DEVICE_SIZES | undefined = undefined;

  if (width > DEVICE_SIZES.xl) size = DEVICE_SIZES.xl;
  else if (width > DEVICE_SIZES.l) size = DEVICE_SIZES.l;
  else if (width > DEVICE_SIZES.m) size = DEVICE_SIZES.m;
  else if (width > DEVICE_SIZES.s) size = DEVICE_SIZES.s;
  return { width, height, size: size || DEVICE_SIZES.xs };
}
