import { useColorScheme } from 'react-native';

const colorsLight = {
  projectCardBackground: 'snow',
  popupBackground: 'snow',
  text: 'black',
  textInverted: 'white',
};

const colorsDark = {
  projectCardBackground: '#222',
  popupBackground: '#444',
  text: 'white',
  textInverted: 'black',
};

const theme = {
  space: {
    s: 6,
    m: 12,
    l: 18,
    xl: 24,
    xxl: 36,
  },
};

const useTheme = () => {
  const colorScheme = useColorScheme();
  return { ...theme, colors: colorScheme === 'dark' ? colorsDark : colorsLight };
};

export default useTheme;

export type Theme = ReturnType<typeof useTheme>;
